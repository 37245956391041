<template>
  <div>
    <div class="intern-bran">
      <div class="top">实习生管理/实习生用人申请</div>
      <div class="bottom">{{ title }}</div>
    </div>
    <div
      class="NewIntern"
      v-loading="loading"
      element-loading-text="请稍后.."
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(255, 255, 255, 1)"
    >
      <template v-if="!loading">
        <el-form
          :inline="true"
          label-position="top"
          :model="formInline"
          :rules="rules"
          ref="NewInternFormRef"
          class="NewIntern-form"
        >
          <el-row :gutter="12">
            <el-col :lg="8">
              <el-form-item label="HFM Code" prop="hfmCode">
                <el-select
                  v-model="formInline.hfmCode"
                  clearable
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in hfmCodeList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :lg="8">
              <el-form-item label="Title" prop="title">
                <el-input v-model="formInline.title" />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="12">
            <el-col :lg="8">
              <el-form-item label="Business Unit Name" prop="businessUnitId">
                <el-select
                  v-model="formInline.businessUnitId"
                  clearable
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in businessUnitIdList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :lg="8">
              <el-form-item label="Line Manager LIID" prop="lineManagerLiid">
                <el-input
                  v-model="formInline.lineManagerLiid"
                  suffix-icon="el-icon-search"
                  @click.native="selectPerson('intern')"
                  placeholder="请选择"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="12">
            <el-col :lg="8">
              <el-form-item
                label="Assigment/project（Level2）"
                prop="assigmentProjectLevel2"
              >
                <el-select
                  v-model="formInline.assigmentProjectLevel2"
                  clearable
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in assigmentProjectLevel2List"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :lg="8">
              <el-form-item label="Line Manager Name" prop="lineManagerName">
                <el-input
                  v-model="formInline.lineManagerName"
                  disabled
                  placeholder="请选择"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="12">
            <el-col :lg="8">
              <el-form-item label="Description（Level3）">
                <el-select
                  v-model="formInline.descriptionLevel3"
                  clearable
                  filterable
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in descriptionLevel3List"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :lg="8">
              <el-form-item label="Client">
                <el-input v-model="formInline.client" />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="12">
            <el-col :lg="8">
              <el-form-item label="Department/Team" prop="">
                <el-select
                  v-model="formInline.departmentId"
                  clearable
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in departmentIdList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :lg="8">
              <el-form-item label="Line Manager Email" prop="lineManagerEmail">
                <el-input
                  v-model="formInline.lineManagerEmail"
                  disabled
                  suffix-icon="el-icon-search"
                  placeholder="请选择"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="12">
            <el-col :lg="8">
              <el-form-item label="Parent Function" prop="parentFunction">
                <el-select
                  v-model="formInline.parentFunction"
                  clearable
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in parentFunctionList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :lg="8">
              <el-form-item label="Child Function" prop="childFunction">
                <el-select
                  v-model="formInline.childFunction"
                  clearable
                  filterable
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in childFunctionList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="12">
            <el-col :lg="8">
              <el-form-item
                label="Reason for position"
                prop="reasonForPosition"
              >
                <el-select
                  v-model="formInline.reasonForPosition"
                  clearable
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in reasonForPositionList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :lg="8">
              <el-form-item label="Leaver Name">
                <el-input
                  v-model="formInline.leaverName"
                  suffix-icon="el-icon-search"
                  @click.native="selectPerson('leaver')"
                  placeholder="Leaver Name"
                />
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <el-row :gutter="12">
          <el-col :lg="2">Attachments</el-col>
          <el-col :lg="2">
            <el-upload
              class="upload-demo"
              ref="upload"
              :on-change="onChange"
              :show-file-list="false"
              :auto-upload="false"
              action="#"
            >
              <el-button slot="trigger" size="small" type="primary"
                >Upload</el-button
              >
            </el-upload>
          </el-col>
          <el-col :lg="5">
            <div class="uploadList">
              <div
                class="uploadList-item"
                v-for="(item, index) in uploadValList"
              >
                <div class="uploadList-item-top">
                  <div class="uploadList-item-left">
                    <div class="uploadList-item-left-img">
                      <img :src="countImgUrl(item.name)" alt="" />
                    </div>
                    <div class="uploadList-item-left-title">
                      {{ item.name }}
                      <br />
                      {{ countKb(item.size) }}
                    </div>
                  </div>
                  <div
                    class="uploadList-item-right"
                    @click.stop="deleteFileList(item, index)"
                  >
                    <img :src="deleteImg" alt="" />
                  </div>
                </div>
                <!--              <el-progress :percentage="item.percentage"></el-progress>-->
              </div>
            </div>
          </el-col>
        </el-row>
        <el-row class="bottomButton">
          <el-button @click="Cancel">Cancel</el-button>
          <el-button type="primary" @click="save">Save</el-button>
          <el-button type="primary" @click="submit">Submit</el-button>
        </el-row>
      </template>
      <personnelSelectionDialog
        ref="personnelSelectionDialogRef"
        @onConfirm="onConfirm"
      />
    </div>
  </div>
</template>
<script>
import pdf from '@/assets/img/Pdf.png'
import deleteImg from '@/assets/img/delete.png'
import jpg from '@/assets/img/jpg.png'
import json from '@/assets/img/JSON.png'
import png from '@/assets/img/png.png'
import word from '@/assets/img/word.png'
import js from '@/assets/img/js.png'
import file from '@/assets/img/file.png'
import {
  getBusinessUnitNameByHfmCode,
  getDepartmentList,
  getEntityHfmCode,
  getIntern,
  hrbpSave
} from '@/api/intern'
import { getChildFunction, getParentFunction } from '@/api/quto'
import { check, typeRange } from '@/utils/util'
import personnelSelectionDialog from '@/components/PersonnelSelectionDialog/PersonnelSelectionDialog'
import {
  getAssigmentProjectLevel2,
  getDescriptionLevel3
} from '@/api/personnelRequisition'
export default {
  name: 'NewIntern',
  components: {
    personnelSelectionDialog
  },
  computed: {
    title() {
      let status = this.$route.query.status
      if (status) {
        if (status === 'edit') {
          return '编辑实习生信息'
        } else {
          return '新增实习生'
        }
      } else {
        return '新增实习生'
      }
    }
  },
  data() {
    return {
      loading: true,
      formInline: {
        hfmCode: '',
        title: '',
        businessUnitId: '',
        assigmentProjectLevel2: '',
        lineManagerLiid: '',
        lineManagerName: '',
        departmentId: '',
        lineManagerEmail: '',
        childFunction: '',
        parentFunction: '',
        descriptionLevel3: '',
        reasonForPosition: '',
        leaverId: '',
        leaverName: '',
        client: '',
        id: ''
      },
      deleteImg,
      businessUnitIdList: [],
      assigmentProjectLevel2List: [],
      descriptionLevel3List: [],
      departmentIdList: [],
      parentFunctionList: [],
      reasonForPositionList: [
        {
          label: 'New',
          value: 'New'
        },
        {
          label: 'Replacement',
          value: 'Replacement'
        }
      ],
      childFunctionList: [],
      tAOwnerList: [],
      dialogStatus: '',
      uploadValList: [],
      fileList: [],
      hfmCodeList: [],
      rules: {}
    }
  },
  async created() {
    Object.keys(this.formInline).forEach((item) => {
      this.rules[item] = [
        {
          required: true,
          trigger: 'blur',
          message: 'The field is required'
        }
      ]
    })
    await this.getFormLine()
    let status = this.$route.query.status
    let id = this.$route.query.id
    if (status && (status === 'edit' || status === 'copy')) {
      if (id) {
        const data = {
          id
        }
        getIntern(data).then((res) => {
          if (res && res.code === 200) {
            if (res.data) {
              Object.keys(this.formInline).forEach((item) => {
                this.formInline[item] = res.data[item] || ''
              })
              // this.fileList = res.data.files
              this.uploadValList = res.data.files
            }
          }
        })
      }
    }
    this.loading = false
  },
  methods: {
    async getFormLine() {
      let HfmCode = await getEntityHfmCode()
      if (HfmCode && HfmCode.code === 200) {
        this.hfmCodeList = HfmCode.data
      }
      let NameByHfmCode = await getBusinessUnitNameByHfmCode()
      if (NameByHfmCode && NameByHfmCode.code === 200) {
        this.businessUnitIdList = NameByHfmCode.data
      }
      let AssigmentProjectLevel2 = await getAssigmentProjectLevel2()
      if (
        AssigmentProjectLevel2 &&
        AssigmentProjectLevel2.code &&
        AssigmentProjectLevel2.code === 200
      ) {
        if (AssigmentProjectLevel2.data) {
          this.assigmentProjectLevel2List = AssigmentProjectLevel2.data
        }
      }
      let DescriptionLevel3 = await getDescriptionLevel3()
      if (
        DescriptionLevel3 &&
        DescriptionLevel3.code &&
        DescriptionLevel3.code === 200
      ) {
        if (DescriptionLevel3.data) {
          this.descriptionLevel3List = DescriptionLevel3.data
        }
      }
      let ParentFunction = await getParentFunction()
      if (ParentFunction && ParentFunction.code === 200) {
        this.parentFunctionList = ParentFunction.data
      }
      let ChildFunction = await getChildFunction()
      if (ChildFunction && ChildFunction.code === 200) {
        this.childFunctionList = ChildFunction.data
      }
      let DepartmentList = await getDepartmentList()
      if (DepartmentList && DepartmentList.code === 200) {
        this.departmentIdList = DepartmentList.data
      }
    },
    onChange(val) {
      this.uploadValList.push(val.raw)
    },
    Cancel() {
      this.$router.push({
        path: '/intern'
      })
    },
    countKb(val) {
      return (val / 1024).toFixed(2) + ' kb'
    },
    deleteFileList(val, index) {
      // this.fileList.splice(index, 1)
      this.uploadValList.splice(index, 1)
    },
    countImgUrl(val) {
      if (val) {
        let name = val.split('.')[1]
        const obj = {
          pdf: {
            value: pdf
          },
          jpg: {
            value: jpg
          },
          json: {
            value: json
          },
          png: {
            value: png
          },
          word: {
            value: word
          },
          js: {
            value: js
          }
        }
        if (obj[name]) {
          return obj[name].value
        } else {
          return file
        }
      }
    },
    selectPerson(val) {
      this.dialogStatus = val
      this.$refs.personnelSelectionDialogRef.show({
        position: val,
        hfmCode: this.formInline.hfmCode
      })
    },
    save() {
      this.$refs.NewInternFormRef.validate((valid) => {
        if (valid) {
          let a = new FormData()
          Object.keys(this.formInline).forEach((item) => {
            a.append(item, this.formInline[item])
          })
          a.append('saveType', '1')
          let objArr = []
          this.uploadValList.forEach((item) => {
            if (check(item) === typeRange.object) {
              objArr.push(item)
            } else {
              a.append('files', item)
            }
          })
          a.append('file', JSON.stringify(objArr))
          // })
          let status = this.$route.query.status
          if (status && status === 'copy') {
            a.set('id', '')
          }
          hrbpSave(a).then((res) => {
            if (res && res.code === 200) {
              this.$message.success('保存成功')
              this.$router.push({
                path: '/intern/internManage'
              })
            }
          })
        } else {
          return undefined
        }
      })
    },
    onConfirm(val) {
      if (val && val.length) {
        if (this.dialogStatus === 'intern') {
          this.formInline.lineManagerLiid = val[0].lineManagerLiid
          this.formInline.lineManagerName = val[0].englishName
          this.formInline.lineManagerEmail = val[0].email
        } else if (this.dialogStatus === 'leaver') {
          console.log(val)
          this.formInline.leaverName = val[0].englishName
          this.formInline.leaverId = val[0].id
        }
      }
    },
    submit() {
      this.$refs.NewInternFormRef.validate((valid) => {
        if (valid) {
          let a = new FormData()
          Object.keys(this.formInline).forEach((item) => {
            a.append(item, this.formInline[item])
          })
          a.append('saveType', '2')
          // a.append('file', JSON.stringify(this.uploadValList))
          let objArr = []
          this.uploadValList.forEach((item) => {
            if (check(item) === typeRange.object) {
              objArr.push(item)
            } else {
              a.append('files', item)
            }
          })
          a.append('file', JSON.stringify(objArr))
          hrbpSave(a).then((res) => {
            if (res && res.code === 200) {
              this.$message.success('提交成功')
              this.$router.push({
                path: '/intern/internManage'
              })
            }
          })
        } else {
          return false
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.intern-bran {
  position: relative;
  width: 110%;
  background: white;
  height: 60px;
  top: -13px;
  left: -14px;
  padding-left: 20px;
  padding-top: 20px;
  .top {
    font-size: 14px;
    color: darkgray;
  }
  .bottom {
    padding-top: 10px;
    font-weight: bold;
  }
}
.NewIntern {
  background: white;
  padding: 30px;
  //margin-top: 70px;
  position: relative;
  height: calc(100vh - 60px - 80px - 100px);
  overflow-x: hidden;
  overflow-y: scroll;

  .NewIntern-form {
  }
  .uploadList {
    //height: 30px;
    height: 150px;
    background: white;
    overflow-x: hidden;
    overflow-y: scroll;
    .uploadList-item {
      border-bottom: 1px solid gray;
      padding-bottom: 5px;
      margin-bottom: 5px;
      .uploadList-item-top {
        //padding: 25px;
        display: flex;

        justify-content: space-between;

        .uploadList-item-left {
          display: flex;
          font-size: 10px;

          .uploadList-item-left-img {
            width: 30px;
            height: 30px;
            margin-right: 10px;

            img {
              width: 100%;
              height: 100%;
            }
          }

          .uploadList-item-left-title {
            font-weight: bold;
            padding-top: 4px;
          }
        }

        .uploadList-item-right {
          width: 15px;
          height: 15px;
          cursor: pointer;
          line-height: 40px;

          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
  .bottomButton {
    text-align: center;
  }
}
</style>
